.lyrico-header {
  display: flex;
  .logo { 
    text-align: left;
    // margin-top: 20px;
    // position: absolute;
    border: none;
    background-color: transparent;
    cursor: pointer;
    
    img {
      width: 50px;
      height: 50px;
    }
  }

  .ui.dropdown {
    .item {
      font-size: 1.5em;
      color: black;
      padding: 5px;

      &:hover {
        background-color: #EA8F22;
        
      }
    }

    i.dropdown.icon {
      display: none;
    }
  }
}