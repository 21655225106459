.ps-page {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;

  .ps-content {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}