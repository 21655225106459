.ui.modal.lyrico-modal {
  text-align: center !important;
  .header {
    text-align: center !important;
    background-color: rgba(175, 122, 57, 0.4);
    border: none;
  }

  .content {
    font-size: 1.3em;
    background-color: rgba(175, 122, 57, 0.4);
    border: none;
  }

  .actions {
    text-align: center;
    background-color: rgba(175, 122, 57, 0.4);
    border: none;
  }
}