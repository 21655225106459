.App {
  text-align: center;
  max-width: 1150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .lyrico-header {
    flex: 1;
  }
  .app-content {
    // margin-top: 20%;
    flex: 9;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footer {
    flex: 1;
  }


  // semantic overrides
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    margin-top: unset;
  }

  h1, h2, h3, h4, h5 {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    line-height: auto;
    margin: unset;
    font-weight: auto;
    padding: auto;
  }
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.lyrico-button {
  padding: 1em 4em;
  background-color: #3372FF;
  color: #fff;
  margin: 10px;
  &:hover {
    background-color: #1F4CB0
  }
  &.secondary {
    background-color: #EA8F22;
    &:hover {
      background-color: #D4821F;
    }
  }
}
