.teams-page {
  display: flex;
  height: 90%;
  justify-content: center;
  flex-direction: column;
  .teams-content {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
  }
}