.welcome-page {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  
  .welcome-content {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .welcome-buttons {
    margin-top: 10%;
  }
}