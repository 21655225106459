.scoring-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .footer-score {
    margin: auto 0 ;
  }

  .active-border { 
    border: 2px dotted #3372FF;
    padding: 10px;
  }

  .inactive-border {
    padding: 12px;
  }
}