.rules-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .rules-paragraph {
    margin-top: 8%;
    height: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1em;
    
    h3:first-child, h3:last-child {
      text-align: center;
      font-size: 1.3em;
    }

  }

}