.game-page {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .game-content {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .lyric {
      color: #3372FF;
    }

    .counter {
      font-size: 4em;
      color: #000;
    }
  }
}